<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Deposits</h3>
                <div class="d-flex">
                  <button @click="showCreateModal = true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                      /></svg
                    >&nbsp; New Deposit
                  </button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody v-if="!isTableLoading && deposits.length > 0">
                  <tr v-for="(deposit, i) in deposits" :key="i">
                    <td>
                      <p>{{ deposit.tag }}</p>
                    </td>
                    <td>
                      <p>{{ deposit.payment_method.name }}</p>
                    </td>
                    <td>
                      <p>{{ deposit.fiat_amount | toCurrency }}</p>
                    </td>
                    <td>
                      <span
                        :class="
                          deposit.status == 'success'
                            ? 'status text-uppercase status-success'
                            : deposit.status == 'failed'
                            ? 'status text-uppercase status-danger'
                            : 'status text-uppercase status-warning'
                        "
                        >{{ deposit.status }}</span
                      >
                    </td>
                    <td>
                      <p>{{ deposit.created_at | formatDate }}</p>
                    </td>
                    <td>
                      <div class="table-actions">
                        <button @click="viewDeposit(deposit.id)">View Details</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="text p-5 text-center text-white d-block"
                v-if="deposits.length == 0 && !isTableLoading"
              >
                No records found! Please try again.
              </div>
              <div class="spinner-wrap" v-if="isTableLoading">
                <b-spinner variant="light" class="mr-2" medium></b-spinner>
                <div class="text">Loading please wait...</div>
              </div>
              <div class="table-bottom">
                <select @change="limitTable()" v-model="limit">
                  <option value="10">10 records</option>
                  <option value="20">20 records</option>
                  <option value="50">50 records</option>
                  <option value="100">100 records</option>
                </select>
                <div class="table-pagination">
                  <button
                    @click="changePage(page)"
                    :class="current_page == page ? 'active' : ''"
                    v-for="(page, i) in total_pages"
                    :key="i"
                  >
                    {{ page }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-modal-bg" @click="showCreateModal = false" v-if="showCreateModal"></div>
    <div class="c-modal" v-if="showCreateModal">
      <div class="c-modal-head">
        <div class="title">Create Deposit</div>
        <button @click="showCreateModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <div class="c-modal-content">
        <div class="v-form-item">
          <span>Payment Method</span>
          <select v-model="deposit_method">
            <option v-for="(method, i) in paymentMethods" :key="i" :value="method.code">
              {{ method.name }} ({{ method.currency }})
            </option>
          </select>
        </div>
        <div class="v-form-item">
          <span>Fiat Amount</span>
          <input type="number" v-model="deposit_amount" />
        </div>
        <button class="main-btn" @click="createDeposit()">Create Deposit</button>
      </div>
    </div>

    <div class="c-modal-bg" @click="showModal = false" v-if="showModal"></div>
    <div class="c-modal" v-if="showModal">
      <div class="c-modal-head">
        <div class="title">Deposit Details</div>
        <button @click="showModal = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
            />
          </svg>
        </button>
      </div>
      <div class="spinner-wrap" v-if="isLoading">
        <b-spinner variant="light" class="mr-2" medium></b-spinner>
        <div class="text">Loading please wait...</div>
      </div>
      <div class="c-modal-content" v-if="!isLoading && depositDetails">
        <div class="order-details">
          <ul>
            <div class="row">
              <div class="col-3">
                <li>
                  <strong>Tag</strong>
                  <span>#{{ depositDetails.tag }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Payment Method</strong>
                  <span>{{ depositDetails.payment_method.name }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Fiat Amount</strong>
                  <span>{{ depositDetails.fiat_amount | toCurrency }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Created At</strong>
                  <span>{{ depositDetails.created_at | formatDate }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Status</strong>
                  <span
                    :class="
                      depositDetails.status == 'success'
                        ? 'status text-uppercase status-success'
                        : depositDetails.status == 'failed'
                        ? 'status text-uppercase status-danger'
                        : 'status text-uppercase status-warning'
                    "
                    >{{ depositDetails.status }}</span
                  >
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Address</strong>
                  <span>{{ depositDetails.address }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Provider Amount</strong>
                  <span>{{ depositDetails.provider_amount | toCurrency }}</span>
                </li>
              </div>
              <div class="col-3">
                <li>
                  <strong>Expires At</strong>
                  <span>{{ depositDetails.expires_at | formatDate }}</span>
                </li>
              </div>
              <div class="col-12">
                <li>
                  <strong>QR Code</strong>
                  <div class="card-alert danger" v-if="errorMessage">
                    {{ errorMessage }}
                  </div>
                  <img :src="qrData" alt="" />
                  <div v-if="qrData"></div>
                </li>
              </div>
              <div class="col-12" v-if="depositDetails.status == 'pending'">
                <div class="v-form-item">
                  <span>Confirmation Link</span>
                  <input
                    type="text"
                    v-model="receipt_link"
                    placeholder="Enter your receipt link"
                  />
                </div>
              </div>
              <div class="col-6" v-if="depositDetails.status == 'pending'">
                <button
                  class="btn btn-danger w-100 mt-3"
                  @click="cancelDeposit(depositDetails.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    /></svg
                  >&nbsp;&nbsp;Cancel Deposit
                </button>
              </div>
              <div class="col-6" v-if="depositDetails.status == 'pending'">
                <button
                  class="btn btn-success w-100 mt-3"
                  @click="confirmDeposit(depositDetails.id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                    /></svg
                  >&nbsp;&nbsp;Confirm Deposit
                </button>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      errors: [],
      deposits: [],
      showModal: false,
      showCreateModal: false,
      depositDetails: null,

      current_page: 0,
      total_pages: 0,
      total_records: 0,

      page: 1,
      limit: 10,

      orderDetails: {},
      isLoading: false,
      isTableLoading: false,

      paymentMethods: [],
      errorMessage: null,
      qrData: null,

      deposit_method: "",
      deposit_amount: "",

      receipt_link: "",
    };
  },
  mounted() {
    this.listDeposits();
    this.listPaymentMethods();
  },
  methods: {
    listDeposits() {
      this.isTableLoading = true;
      this.page = 1;
      axios
        .get(`deposits?page=${this.page}&limit=${this.limit}&payment_methods`)
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.deposits = res.data.records;
          console.log(this.deposits);
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    listPaymentMethods() {
      axios
        .get(`payment-methods`)
        .then((res) => {
          console.log(res.data);
          this.paymentMethods = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    createDeposit() {
      const depositData = {
        payment_method: this.deposit_method,
        fiat_amount: Number(this.deposit_amount),
      };

      axios
        .post(`deposit`, depositData)
        .then((res) => {
          console.log(res.data);
          if (res.status == 201) {
            this.$toast.open({
              message: "Your deposit created successfully.",
              type: "success",
              position: "top-right",
            });
            this.showCreateModal = false;
            this.listDeposits();
          }
        })
        .catch((err) => {
          this.errors.push(err);
          if (err.response.data.code == "DEPOSIT_PENDING") {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top-right",
            });
          } else {
            this.$toast.open({
              message: Object.values(err.response.data.message)[0],
              type: "warning",
              position: "top-right",
            });
          }
        });
    },

    viewDeposit(id) {
      this.isLoading = true;
      this.showModal = true;
      axios
        .get(`deposit/${id}`)
        .then((res) => {
          console.log(res.data);
          this.depositDetails = res.data;
          this.isLoading = false;
          if (res.data.status == "pending") {
            axios
              .get(`deposit/${id}/qr`)
              .then((res) => {
                this.qrData = res.data;
                console.log(this.qrData);
              })
              .catch((err) => {
                if (err.response.data.code == "DEPOSIT_NOT_PENDING") {
                  this.errorMessage = err.response.data.message;
                } else if (err.response.data.code == "QR_UNAVAILABLE_FOR_METHOD") {
                  this.errorMessage = err.response.data.message;
                }
              });
          } else {
            this.errorMessage = "Can only generate QR code for pending deposits";
          }
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    cancelDeposit(id) {
      axios
        .delete(`deposit/${id}`)
        .then((res) => {
          if (res.data.code == "DEPOSIT_CANCELLED") {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-right",
            });
          }
          this.showModal = false;
          this.listDeposits();
        })
        .catch((err) => {
          if (err.response.data.code == "DEPOSIT_NOT_PENDING") {
            this.errorMessage = err.response.data.message;
          }
        });
    },

    limitTable() {
      this.isTableLoading = true;
      this.page = 1;

      axios
        .get(`deposits?page=${this.page}&limit=${this.limit}&payment_methods`)
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.deposits = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    changePage(page) {
      this.isTableLoading = true;
      this.page = page;

      axios
        .get(`deposits?page=${this.page}&limit=${this.limit}&payment_methods`)
        .then((res) => {
          this.current_page = res.data.current_page;
          this.total_pages = res.data.total_pages;
          this.total_records = res.data.total_records;

          this.deposits = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    confirmDeposit(id) {
      if (this.receipt_link != "") {
        axios
          .patch(`deposit/${id}`, {
            confirmation_link: this.receipt_link,
          })
          .then((res) => {
            console.log("confirm dataaa ", res.data);
            if (res.status == 200) {
              this.$toast.open({
                message: "Your pending deposit confirmed successfully!",
                type: "success",
                position: "top-right",
              });
              this.showModal = false;
              this.listDeposits();
            }
          })
          .catch((err) => {
            this.errors.push(err);
            if (typeof err.response.data.message == "object") {
              this.$toast.open({
                message: Object.values(err.response.data.message)[0],
                type: "error",
                position: "top-right",
              });
            } else {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                position: "top-right",
              });
            }
          });
      } else {
        this.$toast.open({
          message: "Please enter your confirmation link!",
          type: "warning",
          position: "top-right",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
